import ProjectOwnerIcon from "components/icons/ProjectOwnerIcon";
import StarHalfIcon from "components/icons/StarHalfIcon";
import TimeIcon from "components/icons/TimeIcon";
import { useState } from "react";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { IoDocument } from "react-icons/io5";
import ProjectReport from "views/admin/ceo/ProjectReport";

function ProjectStatus({ project }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-5 w-full rounded-lg bg-[#F2F9FFCC] py-5 px-2 font-poppins dark:bg-navy-700 lg:px-6 xl:min-w-[460px] 3xl:w-1/4">
      <ProjectReport
        project={project}
        showModal={isOpen}
        setShowModal={setIsOpen}
      />
      <p className="text-lg font-semibold text-gray-700 dark:text-white ">
        Project Details
      </p>
      <div className="mt-5 flex w-full flex-col lg:max-w-[80%] ">
        <div className="flex justify-between">
          <p className="text-sm text-gray-700 dark:text-white ">
            Project Progress
            <span className="ml-2 text-2xl font-bold text-[#485FE5]">
              {Number(project?.completion_percentage || 0).toFixed(2)}%
            </span>
          </p>
        </div>
        <div className="mt-2 flex h-3 w-full items-center rounded-full bg-white">
          <span
            className={`h-full rounded-full bg-[#485FE5]`}
            style={{
              width: `${Number(project?.completion_percentage || 0).toFixed(
                2
              )}%`,
            }}
          />
        </div>
      </div>
      <div className="mt-4 py-2 text-gray-700 dark:text-white ">
        {/* Created at */}
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <TimeIcon />
            <p>Created At</p>
          </div>
          <p className="rounded-lg bg-white py-2 px-4 text-gray-700 lg:min-w-[200px]">
            {new Date(project?.created_date).toDateString()}
          </p>
        </div>
        {/*  Owner */}
        <div className="mt-4 flex justify-between">
          <div className="flex items-center gap-2">
            <ProjectOwnerIcon />
            <p>Project Owner</p>
          </div>
          <p className="rounded-lg bg-white py-2 px-4 text-sm text-gray-700 lg:min-w-[200px]">
            {project?.owned_by_name}
          </p>
        </div>

        {/*  Priority */}
        <div className="mt-4 flex justify-between">
          <div className="flex items-center gap-2">
            <StarHalfIcon />
            <p>Priority</p>
          </div>
          <p className="rounded-lg bg-white py-2 px-4 text-sm capitalize text-gray-700 lg:min-w-[200px]">
            {project?.project_priority}
          </p>
        </div>

        {/* Text details */}
        <div className="mt-6 flex justify-between gap-3">
          <div>
            <p>Project Name</p>
            <p className="rounded-lg bg-white py-4 px-6 text-gray-700 lg:min-w-[200px]">
              {project?.project_name}
            </p>
          </div>
          <div>
            <p>Project Category</p>
            <p className="rounded-lg bg-white py-4 px-6 text-gray-700 lg:min-w-[200px]">
              {project?.project_category_name}
            </p>
          </div>
        </div>
        <div className="mt-6 flex justify-between gap-3">
          <div>
            <p>Project Contract No</p>
            <p className="rounded-lg bg-white py-4 px-2 text-gray-700 lg:min-w-[200px] lg:px-6">
              {project?.project_contract_number}
            </p>
          </div>
          <div>
            <p>Financed by</p>
            <p className="rounded-lg bg-white py-4 px-2 text-gray-700 lg:min-w-[200px] lg:px-6">
              {project?.financed_by}
            </p>
          </div>
        </div>
        <div className="mt-6 flex justify-between gap-3">
          <div>
            <p>Start Date</p>
            <p className="rounded-lg bg-white py-4 px-2 text-gray-700 lg:min-w-[200px] lg:px-6">
              {new Date(project?.start_date).toDateString()}
            </p>
          </div>
          <div>
            <p>End Date</p>
            <p className="rounded-lg bg-white py-4 px-2 text-gray-700 lg:min-w-[200px] lg:px-6">
              {new Date(project?.end_date).toDateString()}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <p>Project Objective</p>
          <p className="mt-1 rounded-lg bg-white p-5 dark:text-gray-700">
            {project?.project_objective}
          </p>
        </div>
        <div className="mt-4">
          <p>Project Report</p>
          <button
            className="mt-1 flex w-full items-center justify-center gap-2 rounded-lg bg-white py-4 px-4 dark:text-gray-700"
            onClick={() => {
              setIsOpen(true);
              // navigate(`/ceo/projects/${project?.id}/report`);
            }}
          >
            <FaExternalLinkSquareAlt
              size={25}
              color="#485FE5"
              className="rounded-md"
            />
            <p className="text-base">Open</p>
          </button>
        </div>
        <div className="mt-4">
          <p>Project Files</p>
          {project?.files?.map((file) => (
            <div key={file.id} className="mt-3 flex items-start gap-5 ">
              <IoDocument color="#2F80ED" size={45} />
              <p className="mt-1 text-sm dark:text-white">
                {file.file.split("/").pop().split(".")[0]}
              </p>
              <a
                href={file.file}
                target="_blank"
                rel="noreferrer"
                className="cursor-pointer text-sm text-blueSecondary underline"
              >
                open
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectStatus;
